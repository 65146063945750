const { linearScale } = require('styled-system-scale')
const { hexToP3 } = require('@walltowall/hex-to-p3')

const sizes = {
  small: '48rem',
  medium: '60rem',
  large: '80rem',
  xlarge: '100rem',
}
const breakpoints = Object.values(sizes)

// Used for linearScale count
const count = breakpoints.length + 1

const theme = {
  /***
   * Sizes
   */
  breakpoints,
  sizes,

  /***
   * Colors
   */
  colors: {
    blue: hexToP3('#00f'),
    teal: {
      40: hexToP3('#4aa2ac'),
      50: hexToP3('#848c8c'),
      60: hexToP3('#6eb5bd'),
      70: hexToP3('#b7dade'),
      80: hexToP3('#d8ddde'),
      90: hexToP3('#edf3f4'),
    },
    gray: {
      10: hexToP3('#2a2e2f'),
      '10transparent': hexToP3('#2a2e2f00'),
      20: hexToP3('#404445'),
      30: hexToP3('#494e4f'),
      40: hexToP3('#5a6061'),
      50: hexToP3('#909090'),
      70: hexToP3('#c4c9c9'),
      80: hexToP3('#dfdfdf'),
      90: hexToP3('#f3f3f3'),
    },
    green: {
      50: hexToP3('#cfd454'),
    },
    white: '#fff',
    black: '#000',
  },

  /***
   * Fonts
   */
  fonts: {
    sans: 'Neutraface, system-ui, sans-serif',
    sansCond: "'Trade Gothic Condensed', system-ui, sans-serif",
    sansExt: "'Trade Gothic Extended', system-ui, sans-serif",
  },
  fontSizeScales: {
    xt: linearScale('8px', '11px', { count }),
    'xt+': linearScale('10px', '11px', { count }),
    't-': linearScale('11px', '13px', { count }),
    t: linearScale('12px', '15px', { count }),
    s: linearScale('17px', '17px', { count }),
    'b-': linearScale('19px', '21px', { count }),
    b: linearScale('19px', '26px', { count }),
    'm+': linearScale('21px', '28px', { count }),
    m: linearScale('19px', '27px', { count }),
    l: linearScale('25px', '30px', { count }),
    'l+': linearScale('28px', '40px', { count: 5 }),
    xl: linearScale('28px', '55px', { count }),
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800,
  },
  lineHeights: {
    solid: 1,
    title: 1.15,
    copy: 1.25,
  },

  /***
   * Spacing
   */
  spaceScales: {
    0: linearScale(0, 0, { count }),
    't-': linearScale('6px', '8px', { count }),
    t: linearScale('12px', '15px', { count }),
    's-': linearScale('15px', '20px', { count }),
    s: linearScale('20px', '20px', { count }),
    's+': linearScale('20px', '28px', { count }),
    m: linearScale('24px', '40px', { count }),
    l: linearScale('40px', '60px', { count }),
    xl: linearScale('45px', '90px', { count }),
    xxl: linearScale('50px', '115px', { count }),
    '3xl': linearScale('65px', '145px', { count }),
  },

  /***
   * Transitions
   */
  transitionDurations: {
    slow: '300ms',
    normal: '200ms',
    fast: '100ms',
  },

  /***
   * Variants
   */
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    trackedCaps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    trackedCapsWide: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    trackedCapsVeryWide: {
      textTransform: 'uppercase',
      letterSpacing: '0.25em',
    },
  },
  boxStyles: {
    lastNoMargin: {
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
    firstLastNoMargin: {
      '&:first-child': {
        marginTop: 0,
        marginLeft: 0,
      },
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
    lastNoBorder: {
      '&:last-child': {
        borderBottom: 0,
        borderRight: 0,
      },
    },
  },

  zIndices: {
    speedBumpModal: 10,
    speedBumpOverlay: 9,
    mobileNavigation: 8,
    header: 7,
    searchBar: 6,
    mobileNavigationOverlay: 5,
    showControlsDrawer: 4,
    controlsDrawer: 3,
    controlsDrawerBG: 1,
  },

  /***
   * Media queries
   */
  mediaQueries: Object.keys(sizes).reduce((acc, key) => {
    acc[key] = `@media screen (min-width: ${sizes[key]}rem)`

    return acc
  }, {}),
}

exports.theme = theme
