// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-diagnostics-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/diagnostics.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-diagnostics-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-guides-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/guides.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-guides-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-index-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/index.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-index-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-layouts-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/layouts.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-layouts-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-prototype-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/prototype.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-prototype-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-slices-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/slices.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-slices-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-types-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/pages/admin/types.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-pages-admin-types-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-example-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/templates/example.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-example-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-guide-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/templates/guide.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-guide-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-layout-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/templates/layout.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-layout-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-slice-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/templates/slice.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-slice-js" */),
  "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-type-js": () => import("./../node_modules/@walltowall/gatsby-theme-ww-prismic-docs/src/templates/type.js" /* webpackChunkName: "component---node-modules-walltowall-gatsby-theme-ww-prismic-docs-src-templates-type-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-unpublished-preview-js": () => import("./../src/pages/unpublishedPreview.js" /* webpackChunkName: "component---src-pages-unpublished-preview-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

